import { selectedSpaceAtom } from "@/atoms/spaces"
import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { DatePicker } from "@/shared/components/ui/date-picker"
import { fetcher } from "@/utils/api"
import { cn } from "@/utils/helpers"
import { faPen } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Checkbox, Tooltip } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import dayjs from "dayjs"
import { useFields } from "hooks/useFields"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import useSWR, { mutate } from "swr"
import { selectedTaskAtom, timelineAlertAtom } from "../utils/atoms"
import { useSpaceConfig } from "@/modules/spaces/hooks/useSpaceConfig"

type PriceAdjustmentProps = {
  onChange(uid: string, kv: Record<string, any>): void
}

export const PriceAdjustments = ({ onChange }: PriceAdjustmentProps) => {
  const [selectedTask, setSelectedTask] = useState<Record<string, any>>()
  const alertState = useRecoilValue(timelineAlertAtom)
  const space = useRecoilValue(selectedSpaceAtom)
  const { fields } = useFields()
  const { data: config } = useSpaceConfig(space?.uid)
  const priceAdjustmentKey = config?.timelineConfig?.priceAdjustmentField
  const priceAdjustmentField = fields?.find(
    (f) => f?.key === priceAdjustmentKey,
  )
  const selectedDate = new Date(selectedTask?.[priceAdjustmentKey])
  const _selectedTask = useRecoilValue(selectedTaskAtom)

  useEffect(() => {
    if (!selectedTask) return
    const date = selectedTask?.[priceAdjustmentKey]
    onChange(selectedTask.uid, { [priceAdjustmentKey]: date })
  }, [selectedTask])

  const onDateChange = (date: Date) => {
    mutate(
      `/tasks/${selectedTask.uid}`,
      (task: any) => ({ ...task, [priceAdjustmentKey]: date.toISOString() }),
      false,
    )
    setSelectedTask((prev) => ({
      ...prev,
      [priceAdjustmentKey]: date.toISOString(),
    }))
  }

  const PriceAdjustmentItem = ({ task: uid }: { task: string }) => {
    const {
      data: task,
      isLoading,
      mutate: mutateTask,
    } = useSWR(uid ? `/tasks/${uid}` : null, fetcher)
    const isSelected = selectedTask?.uid === uid
    const taskDate = task?.[priceAdjustmentKey]
    const isResolved = task?._price_resolved

    useEffect(() => {
      if (task?.uid === _selectedTask && !selectedTask) {
        setSelectedTask(task)
      }
    }, [task])

    const onAddPriceAdjustment = () => {
      const updatedTask = {
        ...task,
        [priceAdjustmentKey]: new Date().toISOString(),
      }
      mutateTask(updatedTask, false)
      setSelectedTask(updatedTask)
    }

    const onResolve = async (e: CheckboxChangeEvent) => {
      const isResolved = e.target.checked
      const data = { _price_resolved: isResolved }
      onChange(uid, data)
      mutateTask((prev) => ({ ...prev, ...data }), false)
    }

    if (isLoading) {
      return <Skeleton className="h-4 w-full" />
    }

    if (!taskDate) {
      return (
        <button
          onClick={onAddPriceAdjustment}
          className="mt-4 text-xs font-medium text-blue-500"
        >
          + Add price adjustment
        </button>
      )
    }

    return (
      <div
        className={cn(
          "relative flex items-center justify-between gap-2",
          isSelected &&
            "before:absolute before:-left-4 before:h-2 before:w-2 before:rounded-full before:bg-blue-400",
        )}
      >
        <p className={cn("text-xs", isSelected && "text-blue-500")}>
          <span className="font-medium">
            {dayjs(taskDate).format("DD. MMM. YY")}
          </span>{" "}
          for task <i>{task?._name ?? "No name"}</i>
        </p>
        <div className="flex items-center gap-2">
          <div
            className={cn(
              "flex items-center justify-center rounded-md px-2 py-1",
              isResolved && "bg-green-100 text-green-400",
              !isResolved && "bg-orange-100 text-orange-400",
            )}
          >
            <p className="text-xs"> {isResolved ? "Resolved" : "Unresolved"}</p>
          </div>
          <Checkbox onChange={onResolve} checked={isResolved} />
          <Tooltip title="Edit period">
            <button onClick={() => setSelectedTask(task)}>
              <FontAwesomeIcon className="text-gray-500" icon={faPen} />
            </button>
          </Tooltip>
        </div>
      </div>
    )
  }

  return (
    <div>
      <p className="mb-2 text-sm font-medium">Price adjustments</p>
      <div className="space-y-2">
        {alertState?.tasks?.map((task) => (
          <PriceAdjustmentItem key={task} task={task} />
        ))}
      </div>
      {!!selectedTask && (
        <div className="mt-4 space-y-2">
          <p className="text-xs font-medium">
            {priceAdjustmentField?.label ?? "Price adjustment date"}
          </p>
          <DatePicker
            date={selectedDate}
            defaultMonth={selectedDate}
            placeholder="Select a date"
            onChange={onDateChange}
          />
        </div>
      )}
    </div>
  )
}
