import { FetchClient } from "@/core/client/fetch_client"
import { StatusCode } from "@/core/generated/api/enums/status_code"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { getService } from "hooks/useStartup"
import { useEffect, useState } from "react"
import { SpaceConfig } from "../models/space_config"

export const useSpaceConfig = (spaceId: string) => {
  const [data, setData] = useState<SpaceConfig | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [code, setCode] = useState<number>(StatusCode.Ok)
  const [message, setMessage] = useState<string>("")

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      let response: ResponseModel | null = null

      try {
        const client: FetchClient = getService(FetchClient)
        response = await client.getAsync(`/v1/spaces/${spaceId}/config`)
        if (response !== null) {
          const model: SpaceConfig | null =
            ResponseSerializer.deserialize<SpaceConfig>(response)
          if (model !== null) {
            setData(model)
            setCode(response.code)
          } else {
            setCode(StatusCode.NoContent)
          }
        } else {
          setCode(StatusCode.BadRequest)
        }
      } catch (err) {
        console.error(err)
        setCode(StatusCode.BadRequest)
      } finally {
        setMessage(response?.message)
        setLoading(false)
      }
    }

    getData()
  }, [spaceId])

  return { data, loading, code, message }
}
